<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    :cols="cols"
    :resourceUrl="resourceUrl"
    :confirmDeleteMessage="confirmDeleteMessage"
    serverPagination
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail
  },
  data() {
    return {
      opts: {}
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 255 } }]
        },
        {
          key: "divisao",
          name: "Divisão",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 255 } }]
        }
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/business-units`;
    }
  },
  methods: {
    confirmDeleteMessage({ divisao, nome }) {
      return `Deseja excluir o registro ${nome} — ${divisao}?`;
    }
  }
};
</script>
